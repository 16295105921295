#mazeContainer {
    top: 15px;
    /* opacity: 0; */
    display: inline-block;
    margin: auto;
    border-radius: 10px;
}

#mazeContainer #mazeCanvas {
    margin: 0;
    display: block;
    border: solid 2px #063ccc;
    height: 500px;

    border-radius: 10px;
}

#view {
    margin-top: 30px;
}

#Maze input, #Maze select {
    font-family: "Inconsolata", monospace, 'Courier Prime', 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    cursor: pointer;
    color: #063ccc;
    padding: 10px;
    border-radius: 20px;
    background-color: #fffeee;
    border: 1px solid #063ccc;
    width: 30%;
    margin-top: 20px;
    text-align: center;
}

#Maze input:hover, #Maze select:hover {
    background-color: #063ccc;
    border: 1px solid #063ccc;
    color: white;
    cursor: pointer;
}

#Maze input:active, #Maze select:active {
    background-color: #063ccc;
}

#Maze input:focus, #Maze select:focus {
    outline: none;
}

/* .custom-select {
    border: 1px solid red;
    width: 100%;
} */

.custom-select select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-repeat: none;
    background-position: 125px center;
}

#Message-Container {
    /* visibility: hidden; */
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    border: 1px solid black;
    background: rgba(0, 0, 0, 0.30);
}

#Message-Container #message #okBtn {
    width: 50%;
    background-color: #063ccc;
    color: white;
}

#Message-Container #message {
    position: fixed;
}

#Maze {
    text-align: center;
    height: auto;
    width: auto;
    margin: auto;
}

#Maze #menu {
    margin: auto;
    height: 65px;
    box-sizing: border-box;
}

#Maze #menu select, #Maze #menu input {
    margin: 10px;
}

#Maze #menu h2 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 3.2rem;
}

.border {
    border: 10px solid black;
    border-radius: 10px;
}


@media only screen and (max-width: 400px) {
	#Maze input, #Maze select {
	    width: 120px;
	}
}

#Landing {
    display: flex;
    height: 90vh;
}

.left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: flex-start;
    padding-left: 30px;
    height:100vh;
    width: 30%;
  }

.right-container .section {
    scroll-snap-align: start;
}

#Landing ul {
    padding: 0px;
}

.right-container img{
    height: 100vh;
}

.right-container {
    width: 70%;
    display: flex;
    align-items: flex-end;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    flex-direction: column;
    text-align: end;
    scroll-snap-type: y mandatory;
}

.right-container::-webkit-scrollbar {
    display: none;
}


@media screen and (max-width:768px) {
    .right-container {
        padding-right: 30px;
        scroll-snap-type: y mandatory;
    }

    .right-container .section {
        scroll-snap-align: start;
    }
}

@media screen and (max-width:700px) {
    #Landing {
        flex-direction: column;
        justify-content: space-between;
        overflow-x: hidden;
        margin-top: 10%;
    }
    #Landing .left-container, #Landing .right-container {
        width:100%;
    }

    #Landing .left-container {
        height: 50vh;
        background: rgba(255, 254, 238, .5);
        /* box-shadow: 0 5px 10px 10px rgba(255, 254, 238, 1); */
    }

    #Landing .right-container {
        text-align: start;
        box-sizing: border-box;
    }

    #Landing .right-container .section {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }

    #MenLayla {
        height: 50vh;
        width: 100vw;
        object-fit: cover;
    }
    #MenLayla img{
        /* width: 40vw; */
        height: 90vh;
        object-fit: cover;
        position: relative;
        top: -200px;
    }
}

@media screen and (max-width:685px) {
    #Landing .left-container h1 {
        font-size: 2.5em;
    }
}



@media screen and (max-width:425px) {
    #Landing .right-container .section {
        width: 100%;
        padding: 0px;
    }
    #Landing .left-container {
        margin-bottom: 100px;
        padding: 0px 30px 0px 30px;
        box-sizing: border-box;
    }

    #Landing .left-container h1 {
        font-size: 2.4rem;
    }


    #Landing .roles {
        width: 100%;
    }
    .right-container {
        overflow: visible;
        width: 100vw;
        padding: 30px;
        box-sizing: border-box;
    }

    #MenLayla img{
        height: 80vh;
        top: -30px;
        left: -100px;
    }


    #MenLayla {
        margin-top: -300px;
        width: 100%;
        margin-bottom: 100px;
    }
    #Landing {
        margin-top: 40%;
    }
}

@media screen and (max-width:417px) {
    #MenLayla img{
        height: 80vh;
    }
}
@media screen and (max-width:375px) {
    #Landing .left-container h1 {
        font-size: 2.6rem;
    }
    #MenLayla img{
        height: 80vh;
    }
}
@media screen and (max-width:320px) {
    #Landing .left-container h1 {
        font-size: 2.0rem;
    }
    #MenLayla img{
        height: 80vh;
        left: -125px;
        top: 0;
    }
}

#ServicesFull {
    margin-top: 60px;
}


#ServicesFull .description{
    font-size: 18px;
    letter-spacing: 4px;
}

#ServicesFull h1 {
    font-size: 64px;
}

#HowItWorks, #WhatIsIncluded {
    padding-top: 100px;
}

#HowItWorks h5 {
    font-size: 24px;
}

#HowItWorks div {
    font-size: 18px;
}

#HowItWorks .step {
    display: flex;
    padding: 60px;
}

#HowItWorks .switch {
    flex-direction: row-reverse;
    text-align: right;
}

#HowItWorks .step .container {
    width: 60%;
    vertical-align: center;
}

#HowItWorks img {
    width: 30%;
    margin-left: 30px;
}

#HowItWorks .coding {
    margin-right: 60px;
}

#HowItWorks .consult {
    width: 50%;
}

#WhatIsIncluded ul {
    margin: 60px;
    letter-spacing: 4px;
}

#WhatIsIncluded {
    height: 80vh;
    box-sizing: border-box;

}

#WorkTogether {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#WorkTogether form {
    width: 50%;
}

@media screen and (max-width:768px) {
    #ServicesFull {
        margin: 30px;
    }
    #ServicesFull .step {
        padding: 30px;
    }

    #WorkTogether form button.extra {
        margin-top: 20px;
    }
    #WorkTogether {
        height: 70vh;
    }
}
@media screen and (max-width:425px) {
    #ServicesFull h1 {
        font-size: 50px;
    }
    #HowItWorks .step {
        flex-direction: column-reverse;
    }
    #HowItWorks .step .container {
        width: 100%;
    }
    #HowItWorks .description {
        font-size: 14px;
    }

    #HowItWorks .step img {
        width: 100%;
        margin: 0px;
    }

    #HowItWorks .step img.coding {
        width: 70%;
    }
    #WhatIsIncluded ul {
        margin: 0px;
        padding: 0px;
        text-align: left;
    }
    #WorkTogether {
        height: 98vh;
    }
    #WorkTogether form {
        width: 100%;
        padding: 0px;
    }
}
@media screen and (max-width:375px) {
    #ServicesFull h1 {
        font-size: 40px;
    }
}

@media screen and (max-width:320px) {
    #WhatIsIncluded ul {
        font-size: 18px;
    }
}

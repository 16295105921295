@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=Karla:ital,wght@0,200..800;1,200..800&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  color: #063ccc;
}

ul {
  list-style-type: none;
}

body {
  margin: auto;
  font-family: "Inconsolata", monospace, 'Courier Prime', 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f6f5f0; */
  height: 100%;
  font-size: 24px;
  background-color: #fffeee;
  box-sizing: border-box;
  width: 90vw;
}

html {
  scroll-behavior: smooth;
}

button {
  font-family: "Inconsolata", monospace, 'Courier Prime', 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: "Futura", sans-serif;
  font-weight: bold;
  font-size: 80px;
  margin: 0px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Futura", sans-serif;
}

h4 {
  font-family: "Futura", sans-serif;
  font-weight: bold;
  font-size: 34px;
  margin: 0px;
}

a:visited {
  color: #063ccc;
}

a {
  text-decoration: none;
}

button {
  color: white;
  padding: 10px;
  border-radius: 30px;
  background-color: #fffeee;;
  border: 1px solid #063ccc;
  width: 30%;
}

.extra {
  padding: 10px;
  border-radius: 30px;
  background-color: #fffeee;;
  border: 1px solid #063ccc;
  width: 30%;
  /* display: flex; */
  align-items: center;
  /* width: 100%; */
  /* justify-content: flex-end; */
  margin-top: 50px;
  position: relative;
  /* margin-top: 10%; */
  background-color: #fffeee;;
  padding: 20px;
  letter-spacing: 5px;
  transition: 0.3s ease-in-out;
  left: 0px;
  top: 0px;
  width: 180px;
  border: 1px solid #063ccc;
  box-shadow: #063ccc 4px 4px 0px 0px;
  color: #063ccc;
  margin-right: 10px;
}

.extra:hover, button:hover {
  left: 4px;
  top: 4px;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px;
  cursor: pointer;
}

.extra .arrow {
  margin-left: 10px;
}

/* Layla footer styling */

#LaylaFooter img{
  width: 300px;
  height: auto;
  object-fit: fill;
  margin-bottom: -10px;
}

#LaylaFooter img:hover{
  cursor: pointer
}

#LaylaFooter {
  display: flex;
  align-items: center;
  font-size: 18px;
}



@media screen and (max-width:1024px) {
  h1 {
    font-size: 60px;
  }
}


@media screen and (max-width:768px) {
  #root {
    width: 100vw;
  }
  h1 {
    font-size: 50px;
  }
  html {
    font-size: 18px;
  }
  body {
    margin: 0px;
  }
}
@media screen and (max-width:425px) {
  h1 {
    font-size: 60px;
  }
  body {
    /* margin: auto; */
    overflow-x: hidden;
    width: 100vw;
  }
}

#ProjectsFull {
    /* display: flex; */
    height: 100%;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#ProjectsFull .header .title {
    padding: 20px 0px 20px;
}

#ProjectsFull .project-block {
    border: 1px #063ccc solid;
    border-radius: 20px;
    width: 25vw;
    font-size: 1rem;
}

#ProjectsFull .project-block h4 {
    font-size: .8rem;
}

#ProjectsFull img.project-img {
    width: 100%;
}


#ProjectsFull .projects-container {
    padding: 0px 20px 0px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width:1024px) {
    #ProjectsFull .project-block {
        font-size: .7rem;
        margin-bottom: 20px;
    }
    #ProjectsFull .project-block .techstack {
        flex-wrap: wrap;
    }
    #ProjectsFull .project-block .tech {
        margin: 2px;
    }
}
@media screen and (max-width:768px) {
    #ProjectsFull {
        margin-left: 30px;
    }
}
@media screen and (max-width:425px) {
    #ProjectsFull .projects-container {
        padding: 0px 20px 0px 20px;
        display: grid;
        grid-template-columns: 1fr;
    }
    #ProjectsFull .project-block {
        width: 80vw;
        font-size: 1rem;
    }
}
@media screen and (max-width:425px) {
    #ProjectsFull h1 {
        font-size: 2.7rem;
    }
    #ProjectsFull .projects-container {
        padding: 0px;
    }
}

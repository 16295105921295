form {
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: start;
    font-size: 18px;
    padding: 30px;
}

#Contact {
    width: 70%;
    padding: 0vh 0px 0vh 0px;
    margin-top: 30vh;

}

form input, form textarea {
    border: 1px solid #063ccc;
    background-color: #fffeee;
    border-radius: 20px;
    padding: 10px;
    margin: 5px;
}

form textarea {
    height: 200px;
}

.message {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media screen and (max-width:425px) {
    #Contact {
        width: 100%;
        margin-bottom: 60px;
    }
    #Contact h4 {
        text-align: start;
    }
    #Contact form {
        padding: 0px;
        width: 100%;
    }
}

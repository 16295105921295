#ProjectDetails img {
    height: 60%;
}

#ProjectDetails {
    border: 1px solid black;
    height: 93vh;
    display: flex;
}

#ProjectDetails .right-container{
    text-align: start;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

#ProjectDetails .right-container div {
    width: 80%;
    border: 1px solid orange;
}

#Projects2 {
    width: 70%;
    padding-top: 6vh;
}

#Projects2 .container button {
    margin-top: 10px;
}


#Projects2 .project-block {
    font-size: 18px;
    text-align: start;
    border-radius: 20px;
    margin: 0px 20px 20px 0px;
    border: 1px solid #063ccc;
    scroll-snap-align: start;
}

#Projects2 h4 {
    margin: 20px 0px 20px 0px;
}

#Projects2 .projects-container {
    display: flex;
    height: auto;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: thin;
    scrollbar-color: blue;
    scroll-snap-type: x mandatory;
}

.projects-container::-webkit-scrollbar {
    display: none;
}

#Projects2 .project-block:hover {
    cursor: pointer;
}

#Projects2 .project-block h4 {
    font-size: 18px;
}

#Projects2 .project-block .info .description {
    margin-top: 20px;
    margin-bottom: 20px;
}

.date-container {
    width: 30%;
}

#Projects2 .project-block .info {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#Projects2 .project-block .info .title {
    margin: 10px 0px 10px 0px;
}

.techstack {
    display: flex;
    color: white;
    margin: 10px 0px 10px 0px;
}

.techstack .tech {
    border: 1px #063ccc solid;
    background-color:#063ccc;
    border-radius: 20px;
    padding: 10px;
    margin: 0px 2px 0px 2px;
}

img.project-img{
    width: 42vw;
    height: 100%;
    border-radius: 20px 20px 0px 0px;
}
.date-container {
    width: 100%;
    padding: 0px 10px 0px 10px;
}
.info {
    padding: 0px 10px 0px 10px;
}




@media screen and (max-width:768px) {
    .techstack {
        flex-wrap: wrap;
    }
    .techstack .tech {
        margin: 2px 2px 2px 2px;
    }
}

@media screen and (max-width:700px) {
    #Projects2 .project-block {
        width: 50vw;
        /* height: 40vh; */
    }

    #Projects2 .project-block img.project-img {
        width: 100%;
    }
}

@media screen and (max-width:425px) {
    #Projects2 {
        width: 100%;
        padding-top: 0vh;
    }
    #Projects2 h4, #Projects .extra {
        text-align: start;
    }
    #Projects2 .container {
        display: flex;
    };
}

@media screen and (max-width:425px) {
    #Projects2 .project-block {
        text-align: start;
        padding: 0px;
        width: 80vw;
        margin: 10px;
        border: 1px solid #063ccc;
    }

    #Projects2 .project-block .techstack {
        width: 80vw;
    }

    img.project-img{
        width: 100%;
        height: auto;
        border-radius: 20px 20px 0px 0px;
    }
    .date-container {
        width: 100%;
        padding: 0px 10px 0px 10px;
    }
    .info {
        padding: 0px 10px 0px 10px;
    }
}

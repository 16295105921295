#AboutMe {
    width: 70%;
    padding-top: 30vh;
    padding-bottom: 30vh;
    margin-top: 20vh;
    margin-bottom: 20vh;
}

#AboutMe div {
    font-size: 20px;
}

@media screen and (max-width:768px) {
    #AboutMe div {
        font-size: 18px;
    }
}

@media screen and (max-width:425px) {
    #AboutMe {
        width: 100%;
        text-align: start;
    }
}

@media screen and (max-width:320px) {
    #AboutMe div {
        font-size: 14px;
        letter-spacing: 2px;
    }
    #AboutMe h4 {
        margin-bottom: 10px;
    }
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0px;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 90px 10px 90px;
    top: 0;
    left: 0;
    position: absolute;
    width: 100vw;
    z-index: 100;
    box-sizing: border-box;
}

nav .logo {
    font-weight: bolder;
    font-size: 32px;
}

.nav-bar li:hover {
    font-weight: bolder;
}

@media screen and (max-width:768px) {
    nav .nav-bar{
        /* border: 1px solid black; */
        width: 80%;

    }
}

@media screen and (max-width:425px) {
    ul {
        flex-direction: column;
        text-align: end;
    }

    nav {
        width: 100vw;
        box-sizing: border-box;
        padding: 10px 30px 0px 30px;
    }
}

.sweater-layla {
    height: 60vh;
}

.treat:hover {
    cursor: pointer;
}

.layla-message {
    border: 1px solid #063ccc;
    padding: 20px;
    border-radius: 30px;
}

.layla-container {
    display: flex;
}

.message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.message-container .buttons, .message-container .buttons button {
    justify-content: end;
    width: 30%;
    margin: 5px;
}

#Layla button {
    width: 100%;
    cursor: pointer;
    border: 1px solid #063ccc;
    background-color: #fffeee;
    color: #063ccc;
}

#Layla button:hover {
    border: 1px solid #063ccc;
    background-color: #063ccc;
    color: white;
}

@media screen and (max-width:425px) {
    .layla-container {
        flex-direction: column-reverse;
        height: 100%;
    }
    .message-container {
        margin: 20px;
        box-sizing: border-box;
    }
    .sweater-layla {
        height: 100%;
    }
}


#Services {
    margin-bottom: 30vh;
    padding: 30vh 0 30vh 0;
}

@media screen and (max-width:425px) {
    #Services, #Services ul li {
        padding: 0vh 0vh 0vh 0;
        width: 100%;
        height: 100%;
        text-align: start;
    }
}
